<template>
    <div class="message-list-box">
        <!-- <div class="message-list-head">
          {{ selectId }}
        </div> -->
      <div v-if="showTops" style="background: #fff;display: flex;align-items: center;justify-content: space-between;padding: 8px 0;padding-right: 12px;">
        <div style="margin-left: 16px;display: flex;align-items: center">
          <img :src="selectUser.avatar" alt="message-list-item-head" class="conversation-list-head" />
          <div style="color: #333;margin-left: 12px;font-weight: bold;">{{selectUser.nickName}}</div>
        </div>
          <div @click='topset' style="padding: 0 6px;background: #FF953E;font-size: 16px;border-radius: 6px;width: fit-content;height: 30px;text-align: center;line-height: 30px;color: #fff;cursor: pointer">{{selectUser.isTop ? '取消置顶' : '置顶'}}</div>
      </div>
        <div class="message-list-mssage" ref="messagebox">
          <div ref="messageboxitem">
            <!--  -->
            <div class="message-title" v-if="listHistoryNum !=0 && (listHistoryNum > historyData.length)" @click="onLoadMore">查看更多</div>
            <div 
                v-for="(item,index) in historyData" 
                :key="index" 
                >
                <div class="message-list-item"  :class="{'message-list-item-right':item.senderUserId == user}"  >
                  
                  <!-- <img src="./logo.png" alt="message-list-item-head" class="conversation-list-head" /> -->
                  <img :src="item.senderUserId == user? myUserInfo.avatar : selectUser.avatar" alt="message-list-item-head" class="conversation-list-head" />
                  <div class="conversation-list-msg">
                   
                      <!-- <div class="conversation-list-msg-title">{{item.senderUserId}}</div> -->
                       <!-- 文字消息 -->
                      <div class="conversation-list-msg-text" v-if="item.messageType == 'RC:TxtMsg'">
                        <!-- {{ item.content.content}} -->
                        <div v-if="item.canIncludeExpansion && item.expansion.data">
                        <div class="bg-white flex padding flex-sub radius-10" v-if="JSON.parse(item.expansion.data).type == 'goods'">
                          <div class="fu-block-180 radius-16 overHidden" >
                            <img class="goods-img" mode="aspectFill" :src="item.expansion|expansion('picture')"></img>
                          </div>
                          <div class="article margin-left flex-sub" style="min-width: 1px;" @click.stop="navToDetail(item)">
                            <div class="fu-fs28 text-cut-2 text-131 text-bold margin-bottom-sm">{{item.expansion|expansion('goodsName') }}</div>
                            <div class="flex justify-between align-center">
                              <div class="text-999 text-sm  margin-bottom-sm text text-through">{{ item.expansion|expansion('marketPrice')}}</div>
                          </div>
                            <div class="text-lg  flex align-center">
                              <p
                                class="text-bold text-prices">
                                <p class="margin-0">￥</p>
                              
                                {{ item.expansion|expansion('price')}}
                            </p>
                              
                            </div>
                          
                            
                          </div>
                        </div>
                        
                      <div class="bg-white  padding flex-sub radius-10" v-if="JSON.parse(item.expansion.data).type == 'order'">
                        <div class="flex align-center justify-between text-666 ">
                          <div class="text-333 fu-fs30 text-bold">
                            <p class="margin-0">订单编号: {{ item.expansion|expansion('id')}}</p>
                            
                          </div>
                          <div>
                            <p class="text-theme margin-0 fu-fs28 text-bold">{{ item.expansion|expansion('orderStatusName')}}</p>
                          </div>
                         
                        </div>
                       
                        <div class="flex align-stretch padding-bottom">
                          <div class="fu-block-180 margin-right-sm">
                            <img class="goods-img" :src="item.expansion|expansion('picture')" radius="8" mode="aspectFill"></img>
                          </div>
                          <div class="flex-sub flex flex-direction justify-between" style="min-width: 1px;">
                            <div>
                              <div class="text-cut-2 text-333  fu-fs28">{{item.expansion|expansion('goodsName') }}</div>
                              <div class="fu-fs24 text-999 text-cut margin-top-xs">{{item.expansion|expansion('skuName') }}</div>
                            </div>
                          </div>
                        </div>
                          
                        <div class="flex-sub">
                          <div class="flex align-center justify-end">
                            <p class="text-bold text-333">
                              <p class="fu-fs20 margin-0">￥</p>
                              <p class="fu-fs32 margin-0">{{ item.expansion|expansion('price') }}</p>
                            </p>
                          </div>
                        </div>
                        
                      </div>
                      </div>
						
						          <p class="margin-0" v-else>{{ item.content.content}}</p>
                      </div>
                      <div class="conversation-list-msg-text" v-if="item.messageType==2">
                       {{  item.expansion}}
                      <div v-if="item.expansion&&item.expansion.data">
                        <div class="bg-white flex padding flex-sub radius-10" v-if="JSON.parse(item.expansion.data).type == 'goods'">
                          <div class="fu-block-180 radius-16 overHidden" >
                            <img class="goods-img" mode="aspectFill" :src="item.expansion|expansion('picture')"></img>
                          </div>
                          <div class="article margin-left flex-sub" style="min-width: 1px;" @click.stop="navToDetail(item)">
                            <div class="fu-fs28 text-cut-2 text-131 text-bold margin-bottom-sm">{{item.expansion|expansion('goodsName') }}</div>
                            <div class="flex justify-between align-center">
                              <div class="text-999 text-sm  margin-bottom-sm text text-through">{{ item.expansion|expansion('marketPrice')}}</div>
                          </div>
                            <div class="text-lg  flex align-center">
                              <p
                                class="text-bold text-prices">
                                <p class="margin-0">￥</p>
                              
                                {{ item.expansion|expansion('price')}}
                            </p>
                              
                            </div>
                          
                            
                          </div>
                        </div>
                        
                      <div class="bg-white  padding flex-sub radius-10" v-if="JSON.parse(item.expansion.data).type == 'order'">
                        <div class="flex align-center justify-between text-666 ">
                          <div class="text-333 fu-fs30 text-bold">
                            <p class="margin-0">订单编号: {{ item.expansion|expansion('id')}}</p>
                            
                          </div>
                          <div>
                            <p class="text-theme margin-0 fu-fs28 text-bold">{{ item.expansion|expansion('orderStatusName')}}</p>
                          </div>
                         
                        </div>
                       
                        <div class="flex align-stretch padding-bottom">
                          <div class="fu-block-180 margin-right-sm">
                            <img class="goods-img" :src="item.expansion|expansion('picture')" radius="8" mode="aspectFill"></img>
                          </div>
                          <div class="flex-sub flex flex-direction justify-between" style="min-width: 1px;">
                            <div>
                              <div class="text-cut-2 text-333  fu-fs28">{{item.expansion|expansion('goodsName') }}</div>
                              <div class="fu-fs24 text-999 text-cut margin-top-xs">{{item.expansion|expansion('skuName') }}</div>
                            </div>
                          </div>
                        </div>
                          
                        <div class="flex-sub">
                          <div class="flex align-center justify-end">
                            <p class="text-bold text-333">
                              <p class="fu-fs20 margin-0">￥</p>
                              <p class="fu-fs32 margin-0">{{ item.expansion|expansion('price') }}</p>
                            </p>
                          </div>
                        </div>
                        
                      </div>
                      </div>
						
						          <p class="margin-0" v-else> {{ item.text}}</p>
                      </div>
                    <div class="conversation-list-msg-text" v-if="item.messageType == 'RC:HQVCMsg'"> <p class="margin-0" >语音消息请在APP/小程序中查看</p></div>
                      <!-- 图片消息 -->
                      <div class="" v-if="item.messageType == 'RC:ImgMsg'">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->
                          <!-- {{ item.content.imageUri }} -->
                          <img 
                              class="conversation-list-msg-image"
                              :src="item.content.imageUri || item.content.content" 
                              :prediv-src-list="[item.content.imageUri || item.content.content]">
                            </img>
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                      <div class="" v-if="item.messageType == 4">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->
                          <!-- {{ item.content.imageUri }} -->
                          <img 
                              class="conversation-list-msg-image"
                              :src="item.remote" 
                              :prediv-src-list="[item.remote]">
                            </img>
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                      <!-- 视频消息 -->
                      <div class="video-msg-box" v-if="item.messageType == 'RC:SightMsg'" @click="onPlayVideo(item.content)">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->

                          <img 
                              class="conversation-list-msg-image"
                              src="./video.jpg" >
                            </img>
                            <!-- <video class="conversation-list-msg-image" :src="item.content.sightUrl"></video> -->
                            <i class=" el-icon-video-play  play-icon"></i>
                           
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                       <!-- 视频消息 -->
                       <div class="video-msg-box" v-if="item.messageType == 6" @click="onPlayVideo(item.remote)">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->

                          <img 
                              class="conversation-list-msg-image"
                              src="./video.jpg" >
                            </img>
                            <!-- <video class="conversation-list-msg-image" :src="item.content.sightUrl"></video> -->
                            <i class=" el-icon-video-play  play-icon"></i>
                           
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                    
                      <!-- 自定义消息  商品链接 -->

                      <!-- 自定义消息  订单链接 -->
                       
                  </div>
                </div>
                <!-- {{ item }} -->
               
            </div>
            
            <div 
                v-for="(item,index) in listData" 
                :key="index" 
                >
                <div class="message-list-item"  :class="{'message-list-item-right':item.messageDirection == 1}"  >
                  
                  <img :src="item.messageDirection == 1?myUserInfo.avatar:selectUser.avatar" alt="message-list-item-head" class="conversation-list-head" />
                  <div class="conversation-list-msg">
                   
                      <!-- <div class="conversation-list-msg-title">{{item.senderUserId}}</div> -->
                       <!-- 文字消息 -->
                      <div class="conversation-list-msg-text" v-if="item.messageType == 'RC:TxtMsg'">
                        <!-- {{ item.content.content}} -->

                           
                      <div v-if="item.canIncludeExpansion && item.expansion.data">
                        <div class="bg-white flex padding flex-sub radius-10" v-if="JSON.parse(item.expansion.data).type == 'goods'">
                          <div class="fu-block-180 radius-16 overHidden" >
                            <img class="goods-img" mode="aspectFill" :src="item.expansion|expansion('picture')"></img>
                          </div>
                          <div class="article margin-left flex-sub" style="min-width: 1px;" @click.stop="navToDetail(item)">
                            <div class="fu-fs28 text-cut-2 text-131 text-bold margin-bottom-sm">{{item.expansion|expansion('goodsName') }}</div>
                            <div class="flex justify-between align-center">
                              <div class="text-999 text-sm  margin-bottom-sm text text-through">{{ item.expansion|expansion('marketPrice')}}</div>
                          </div>
                            <div class="text-lg  flex align-center">
                              <p
                                class="text-bold text-prices">
                                <p class="margin-0">￥</p>
                              
                                {{ item.expansion|expansion('price')}}
                            </p>
                              
                            </div>
                          
                            
                          </div>
                        </div>
                        
                      <div class="bg-white  padding flex-sub radius-10" v-if="JSON.parse(item.expansion.data).type == 'order'">
                        <div class="flex align-center justify-between text-666 ">
                          <div class="text-333 fu-fs30 text-bold">
                            <p class="margin-0">订单编号: {{ item.expansion|expansion('id')}}</p>
                            
                          </div>
                          <div>
                            <p class="text-theme margin-0 fu-fs28 text-bold">{{ item.expansion|expansion('orderStatusName')}}</p>
                          </div>
                         
                        </div>
                       
                        <div class="flex align-stretch padding-bottom">
                          <div class="fu-block-180 margin-right-sm">
                            <img class="goods-img" :src="item.expansion|expansion('picture')" radius="8" mode="aspectFill"></img>
                          </div>
                          <div class="flex-sub flex flex-direction justify-between" style="min-width: 1px;">
                            <div>
                              <div class="text-cut-2 text-333  fu-fs28">{{item.expansion|expansion('goodsName') }}</div>
                              <div class="fu-fs24 text-999 text-cut margin-top-xs">{{item.expansion|expansion('skuName') }}</div>
                            </div>
                          </div>
                        </div>
                          
                        <div class="flex-sub">
                          <div class="flex align-center justify-end">
                            <p class="text-bold text-333">
                              <p class="fu-fs20 margin-0">￥</p>
                              <p class="fu-fs32 margin-0">{{ item.expansion|expansion('price') }}</p>
                            </p>
                          </div>
                        </div>
                        
                      </div>
                      </div>
						
						          <p class="margin-0" v-else>{{ item.content.content}}</p>
                      </div>
<!--                    语音消息-->
                    <div class="conversation-list-msg-text" v-if="item.messageType == 'RC:HQVCMsg'"> <p class="margin-0" >语音消息请在APP/小程序中查看</p></div>
                      <!-- 图片消息 -->
                      <div class="" v-if="item.messageType == 'RC:ImgMsg'">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->
                          <!-- {{ item.content.imageUri }} -->
                          <img 
                              class="conversation-list-msg-image"
                              :src="item.content.imageUri || item.content.content" 
                              :prediv-src-list="[item.content.imageUri || item.content.content]">
                            </img>
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                      <!-- 视频消息 -->
                      <div class="video-msg-box" v-if="item.messageType == 'RC:SightMsg'" @click="onPlayVideo(item.content)">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->

                          <img 
                              class="conversation-list-msg-image"
                              src="./video.jpg" >
                            </img>
                            <!-- <video class="conversation-list-msg-image" :src="item.content.sightUrl"></video> -->
                            <i class=" el-icon-video-play  play-icon"></i>
                           
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                      <!-- 自定义消息  商品链接 -->

                      <!-- 自定义消息  订单链接 -->
                       
                  </div>
                </div>
                <!-- {{ item }} -->
               
            </div>
          </div>
            
        </div>
        
        <a-modal
              v-model="dialogVisible"
              :destroyOnClose="true"
              :footer="null"
              :closable="true"
               style="text-align: center; ">
               <!-- {{ dialogVisible }} -->
          <!-- <player :video="video" v-if="playerVisible"></player> -->
              <video ref="videoPlayer" v-if="videoUrl && dialogVisible" class="video-item" controls @click="onplay">
                <source :src="videoUrl" type="video/mp4">
              </video>
        </a-modal>


    </div>
  </template>
  
  <script>
  import {mapGetters} from 'vuex'
  import {pageShop, listShop, delShop, updateShopRecommend,ptkf,queryAutographSign,queryKfUserList,
queryKfNoticePage,
queryKfNoticeCount} from '@/api/shop/shop'
   const RongIMLib = require('@rongcloud/imlib-next')
  export default {
    name: 'ConversationList',
    props: {
      msg: String,
      msgArry: Array,
      selectId: String,
      user:String,
      selectUser:Object,
      myUserInfo: Object,
      showTops: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        listData:[],
        historyData:[],
        hasMore:true,
        dialogVisible: false,
        videoUrl:'',
        listHistoryNum:0,
        listHistory:[],
        listHistoryTime:''
      };
    },
    computed: {
    ...mapGetters([
      'avatar',
      'nickname'
    ])
  },
    watch: {
      selectId: {
      handler(newVal, oldVal) {
        console.log(newVal,oldVal)
        this.getHitoryList()
      },
      deep: true
    }
  },
  filters: {
		  
		   expansion(data,type){
			   console.log('订单信息',data)
			   if(data.data){
				   let userData = JSON.parse(data.data)
				   return userData[type]
			   }else{
				   return ''
			   }
		   
		   }
		 },
    methods: {
      resSendMsgTrack(messageUId,timestamp) {
        console.log(messageUId)
        RongIMLib.sendReadReceiptMessage(this.selectId, messageUId, timestamp).then(res => {
          if (res.code === 0) {
            console.log(res.code, res.data)
          } else {
            console.log(res.code, res.msg)
          }
        }).catch(error => {
          console.log(error)
        })
      },
      topset() {
        this.$emit('changTops',this.selectUser)
      },
      getHitoryList(){
        console.log('获取历史记录')
        let that = this;
        const conversation = {
            targetId: this.selectId,
            conversationType: RongIMLib.ConversationType.PRIVATE
          };
          const option = {
            // 获取历史消息的时间戳，默认为 0，表示从当前时间获取
            timestamp: +new Date(),
            // 获取条数，有效值 1-100，默认为 20
            count: 10,
          };
          RongIMLib.getHistoryMessages(conversation, option).then(result => {
            console.log('获取历史消息成功', result);
            that.listData = result.data.list;       // 获取到的消息列表
            that.listHistoryTime =  result.data.list[0].sentTime
            that.getLoadMoreType()
            console.log('获取历史消息成功', result);
            const hasMore = result.data.hasMore; // 是否还有历史消息可获取
            that.hasMore = hasMore
            // console.log('获取历史消息成功', list, hasMore);
        // that.scrollBottom()
            console.log(result.data.list)
            if(result.data.list.length > 0) {
              result.data.list.map(keys => {
                this.resSendMsgTrack(keys.messageUId,keys.sentTime)
              })
            }
        setTimeout(()=>{
          that.scrollBottom()
        },300)
          }).catch(error => {
            console.log('获取历史消息失败', error,error.code, error.msg);
          });
      },

      upDataList(data){
        console.log('子集更新list',data)
        let that = this;
        that.listData.push(data)
       
        setTimeout(()=>{
          that.scrollBottom()
        },300)

      },

      // 查看更多状态
      getLoadMoreType(){
        let that = this;
        queryKfNoticeCount({
            targetId: this.selectId,
						senderUserId: this.user,
						sentTime: this.listHistoryTime
        }).then(response => {
          console.log('客服--------------',response)
          that.listHistoryNum = response.data
        })
      },
      //查看更多
      onLoadMore(){
        let pageNum = Math.ceil(this.historyData.length/10) + 1
        queryKfNoticePage({
            targetId: this.selectId,
						senderUserId: this.user,
						sentTime: this.listHistoryTime,
            pageNum:pageNum,
						pageSize:10
        }).then(response => {
          console.log('客服--------------',response)
            let usedata = response.data.records.reverse();
            
          console.log('客服--------------',usedata)
            this.historyData = usedata.concat(this.historyData)
            console.log('历史--------------', this.historyData)
        })
      },
	
      scrollBottom(){
        let that = this;
        this.$nextTick(()=>{
          that.$refs.messagebox.scrollTop = that.$refs.messageboxitem.scrollHeight
        })
        
      },

      onPlayVideo(item){
        this.videoUrl = ''
        this.videoUrl = item.sightUrl;
        this.dialogVisible = true
      },

      onplay(){
        this.$refs.videoPlayer.play(); 
      }
    }

 
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style  scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .message-list-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eee;
    background-color: #fff;
  }
  .message-list-head{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
  }
  .message-list-item{
    display: flex;
    padding: 16px;
   }
  .message-list-item-right{
    display: flex;
    /* justify-content: flex-end; */
    flex-direction: row-reverse;
  }
  .message-list-item-right .conversation-list-msg{
    background-color: #ff953e;
    color: #fff;
    border-radius: 18px 0px  18px 18px;
  }
  .message-list-item-head{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #eee;
  }
  .conversation-list-item{
    display: flex;
    padding: 16px;
    background-color: #e5e4e4;
    border-bottom: 1px solid #eee;
  }

  .message-list-mssage{
      flex: 1;
      overflow-y: auto;
    background: #f8f8f8;
      
  }

.message-list-mssage::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}
.message-list-mssage::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}
.message-list-mssage::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}
.message-list-mssage::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.message-list-mssage::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

  .messageboxitem{
    transition: all 1s ease;
  }
  .active{
    background-color: #ff953e;
  }
  .conversation-list-head{
    width: 50px;
    height: 50px;
    border: 1px solid #eee;
    border-radius: 50%;
  } 
  .conversation-list-msg{
    /* flex: 1; */
    text-align: left;
    margin:0 16px;
    background-color: #eee;
    padding: 0 10px;
    border-radius: 10px;
    max-width: 80%;
    word-break: break-all;
    border-radius: 0px 18px  18px 18px;
  }
  .conversation-list-msg-text{
    line-height: 50px;

  }
  .conversation-list-msg-title{
    font-weight: bold;
    margin-bottom: 6px;
  }
  .conversation-list-msg-image{
    margin:10px 0;
    width: 120px;
    display: block;
  }
  .video-item{
    max-width: 600px;
    max-height: 600px;
  }
  .video-msg-box{
    position: relative;
  }
  .play-icon{
    position: absolute;
    top: calc(50% - 25px) ;
    left: calc(50% - 25px) ;
    color: #fff;
    font-size: 50px;
  }
  .message-title{
    text-align: center;
    color: #1890ff;
  }


 
   .text-box-left {
    border-radius: 20px;
    padding: 14rpx 30px;
    font-size: 32px;
    line-height: 48px;
    max-width: 540px;
    position: relative;
}
.bg-white {
    background-color: #fff;
    color: #666;
    margin: 10px 0;
}
.margin-left {
    margin-left: 16px;
}
.radius-10 {
    border-radius: 10px!important;
}
.radius-16 {
    border-radius: 16px!important;
}
.fu-block-180 {
    width: 90px!important;
    height: 90px!important;
}
.overHidden {
    overflow: hidden;
}
.text-131 {
    color: #131d34!important;
}
.fu-fs28 {
    font-size: 14px!important;
}
.text-cut-2 {
    -webkit-line-clamp: 2;
}
.text-cut-2, .text-cut-3, .text-cut-4, .text-cut-5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}
.text-bold {
    font-weight: 700;
}
.margin-bottom-sm {
    margin-bottom: 10px;
}
.goods-img{
  width: 100%;
  height: 100%;
}
.align-center {
    align-items: center;
}
.text-lg {
    font-size: 16px;
}
.fu-fs30{
  font-size: 16px;
}
.fu-fs28{
  font-size: 14px;
}
  </style>
  